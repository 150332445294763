<template>
  <div class="material-wrap">
      <div class="bg-wrapper">
         <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="日期" width="120">
        <template slot-scope="scope">{{ scope.row.date }}</template>
      </el-table-column>
      <el-table-column prop="name" label="姓名" width="120"></el-table-column>
      <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
    </el-table> 
      </div>
    
  </div>
</template>
<script>
import indexApi from '@/api/index'
export default {
  data() {
    return {
        tableData:[],
        multipleSelection:[],
        params:{
            displayRecord:'',
            isPage:'',
            page:1,
            quickSearch:'',
            size:20,
            start:"",
            where:'',
        }
    };
  },
  created() {
      this.getMaterial();
  },
  methods: {
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      getMaterial(){
          let that = this;
         indexApi.getMaterial(this.params,'employee').then((res)=>{
        // eslint-disable-next-line no-empty
        if(res && res.code === 200){
          that.list = res.object?.list;
         that.list.map(item=>{
           return item.startDate = that.$tools.formatDated(item.startDate);
         })
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
      }
  }
};
</script>
<style scoped lang="scss">
.material-wrap {
	background-image: url('~./images/bg.jpg');
	background-size: cover;
	line-height: 1;
}
.bg-wrapper {
	display: flex;
	align-items: flex-start;
	// background: url("~./images/content-bg.png") center center /100% 100%;
	background: rgba(255, 255, 255, 0.7);
	height: 7.3rem;
	margin-top: 0.6rem;
	position: relative;
	padding: 0.3rem;
	// padding-top: .4rem;

	border-radius: 0.2rem;
    max-width: 16rem;
    margin: 0 auto;
	// opacity: .9;
}

</style>